<template>
  <div>
    <div>
      <headerTop />
    </div>
    <div class="header_min bg1">
      <div class="wrap header_min_box">
        <div class="fl logo" @click="$router.push('/')">
          <img src="@/assets/image/logo_shape@2x.png" alt />
          联众医药
        </div>
        <slot></slot>
      </div>
    </div>
    <div class="pay_box" v-if="state == 0">
      <div class="pay">
        <p>填写核对订单信息</p>
        <div class="main">
          <div class="pay_mode">
            <p>支付方式</p>
            <div>
              <span
                @click="activeType = 'alipayScan'"
                :class="{active: activeType == 'alipayScan'}"
              >支付宝</span>
              <span
                @click="activeType = 'wechatScan'"
                :class="{active: activeType == 'wechatScan'}"
              >微信</span>
              <span @click="activeType = 'balance'" :class="{active: activeType == 'balance'}">账户余额</span>
            </div>
          </div>
          <div class="details_list">
            <p>商品清单</p>
            <div class="fl">
              <img :src="expertInfo.avatar" alt />
              <!-- <img src="@/assets/image/Furtherconsultation_Defaulthead@2x.png" alt /> -->
              <p>{{expertInfo.realname}}-专家-{{inquiryType}}</p>
            </div>
            <div class="fr">￥{{ price }}</div>
          </div>
        </div>
        <div class="tijiao">
          <div class="fr">
            <p>
              应付金额
              <span class="price">￥{{ price }}</span>
            </p>
            <button @click="submitOrder">确认支付</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 支付 -->
    <div class="pay_content" v-if=" state != 0">
      <div class="content bgw">
        <div v-show="state==1" class="wrap box">
          <div v-if="activeType == 'alipayScan'" class="titles">支付宝</div>
          <div v-if="activeType == 'wechatScan'" class="titles">微信支付</div>
          <!-- <div class="top">
          二维码已过期,
          <span>刷新</span> 重新获取二维码
          </div>-->
          <div class="top"></div>
          <div class="pay_imgs">
            <div v-if="activeType != 'balance'" id="qrcode" ref="qrcode"></div>
            <div v-else>
              <input v-model="payCode" type="password" />
              <div class="confirm_pay" @click="confirmPay">确认支付</div>
              <el-dialog title="余额支付" :visible.sync="dialogVisible" width="50%">
                <span>是否确认从余额内付款 {{price}}</span>
                <span slot="footer" class="dialog-footer">
                  <el-button @click="payFily()">取 消</el-button>
                  <el-button type="primary" @click="tabSend()">确 定</el-button>
                </span>
              </el-dialog>
            </div>
          </div>
          <div v-if="activeType == 'alipayScan'" class="pay_txt">请用支付宝扫码支付</div>
          <div v-if="activeType == 'wechatScan'" class="pay_txt">请用微信扫码支付</div>
          <div v-if="activeType == 'balance'" class="pay_txt">请输入支付密码</div>

          <div @click="state=0" class="tabPay">返回</div>
        </div>
        <div v-show="state==2" class="wrap box">
          <div class="titles mb">选择支付方式</div>
          <div
            @click="activeType='wechatScan'"
            :class="{tabActive:activeType=='wechatScan'}"
            class="tab1"
          >
            <img
              v-show="activeType!='wechatScan'"
              class="img1"
              src="@/assets/image/Cashier_Choice_Unchecked@2x.png"
              alt
            />
            <img
              v-show="activeType=='wechatScan'"
              class="img1"
              src="@/assets/image/Cashier_Choice_Selection@2x.png"
              alt
            />
            <img class="img2" src="@/assets/image/Cashier_wixin_icon@2x.png" alt />
            微信支付支付
          </div>
          <div
            @click="activeType='alipayScan'"
            :class="{tabActive:activeType=='alipayScan'}"
            class="tab1"
          >
            <img
              v-show="activeType!='alipayScan'"
              class="img1"
              src="@/assets/image/Cashier_Choice_Unchecked@2x.png"
              alt
            />
            <img
              v-show="activeType=='alipayScan'"
              class="img1"
              src="@/assets/image/Cashier_Choice_Selection@2x.png"
              alt
            />
            <img class="img2" src="@/assets/image/Cashier_zhifubao_icon@2x.png" alt />
            支付宝支付
          </div>
          <button @click="confirm()" class="confirm">确定</button>
        </div>
      </div>
    </div>
    <div>
      <publicBottom />
    </div>
  </div>
</template>

<script>
import headerTop from "@/components/public/public_headerTop.vue";
import publicBottom from "@/components/public/public_bottom.vue";
import QRCode from "qrcodejs2";
import { getinfo } from "@/request/index"; // 医生详情
import { inquiryPay } from "@/request/user";
import { mapState } from "vuex";
export default {
  components: {
    headerTop,
    publicBottom
  },
  data() {
    return {
      activeType: "alipayScan",
      expertInfo: {},
      price: 10000,
      state: 0,
      dialogVisible: false,
      codeImg: "",
      payCode: ""
    };
  },
  computed: {
    ...mapState(["sendMessage"])
  },
  created() {
    this.id = this.$route.query.id;
    this.uid = this.$route.query.uid;
    this.inquiryType = this.$route.query.inquiryType;
    this.inquiry_id = this.$route.query.inquiry_id;
    this.inquiry_doctor_id = this.$route.query.inquiry_doctor_id;
    this.start();
  },
  methods: {
    start() {
      getinfo({
        id: this.id
      }).then(res => {
        this.expertInfo = res.data;
        if (this.inquiryType == "图文问诊") {
          this.price = res.data.words_price;
        } else {
          this.price = res.data.video_price;
        }
      });
      let state = {
        method: "get_pay_status",
        data: {
          inquiry_doctor_id: this.inquiry_doctor_id
        }
      };
      this.sendMessage(JSON.stringify(state));
    },
    submitOrder() {
      this.typeInquriryPay(this.activeType);
      this.state = 1;
    },
    qrcodeScan(pay_img) {
      let qrcode = new QRCode("qrcode", {
        width: 302, // 二维码宽度
        height: 302, // 二维码高度
        text: pay_img
      });
    },
    typeInquriryPay(type) {
      inquiryPay({
        token: this.$token,
        inquiry_id: this.inquiry_id,
        inquiry_doctor_id: this.inquiry_doctor_id,
        pay_type: type,
        pay_password: this.payCode
      }).then(res => {
        if (res.code == 1) {
          if (res.data.qr) {
            this.pay_img = res.data.qr;
            this.qrcodeScan(this.pay_img);
          }
        } 
      });
    },
    confirm() {
      this.state = 0;
    },
    payFily() {
      this.dialogVisible = false;
    },
    tabSend() {
      this.typeInquriryPay(this.activeType);
      this.dialogVisible = false;
    },
    confirmPay() {
      if (this.payCode == "") {
        this.$message({
          type: "error",
          message: "请输入支付密码"
        });
        return;
      }
      this.dialogVisible = true;
    }
  }
};
</script>

<style lang="less"  scoped>
@import "../../../assets/less/header";
@import "./expert.less";
@import "../../../assets/less/index";
// 支付修改
.pay_content {
  margin-top: 40px;
  .tabPay {
    cursor: pointer;
  }
}
.pay_imgs {
  position: relative;
  input {
    position: absolute;
    top: 100px;
    width: 300px;
    left: 0px;
    line-height: 30px;
    font-size: 20px;
    text-indent: 8px;
    border: 2px solid #38eec1 !important;
  }
  .confirm_pay {
    position: absolute;
    top: 420px;
    right: -280px;
    font-size: 20px;
    color: #38eec1;
    cursor: pointer;
  }
}
</style>